<template>
  <v-container fluid>
    <!--Breadcrumbs-->
    <Breadcrumbs :breadcrumbs="breadcrumbs" />

    <!--Table data-->
    <data-table 
    :headers="headers"
    :data="data"
    :loading="loading"
    :pageNumber="pageNumber"
    :paginate="paginate"
    :options="options"
    >
      <component :is="headerChildComponent"></component>
    </data-table>
      
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { itemsSpan, initialize } from '../helpers/index'
import DataTable from '../components/common/DataTable.vue'
import Header from '../components/subscriptions/Header'
import Breadcrumbs from '../components/common/Breadcrumbs.vue'
export default {
    components:{
      DataTable,
      Header,
      Breadcrumbs
    },
    data(){
        return{
          breadcrumbs: [
            {
                text: 'Dashboard',
                href: '/',
            },
            {
                text: 'Subscriptions',
                href: '/subscriptions',
            },
        ],
        headers: [
            { text: 'Created at', value: 'created' },
            { text: 'Status', value: 'status' },
            { text: 'Current period start', value: 'current_period_start' },
            { text: 'Current period end', value: 'current_period_end' },
            { text: 'Collection method', value: 'collection_method' },
            { text: 'Plan', value: 'plan_id' }
        ],
        headerChildComponent: 'Header',
        options:{
          statusChips: ['status'],
          timestamps: [ 'created', 'current_period_start', 'current_period_end' ]
        }
      }
    },
    computed: {
        ...mapState('subscriptions', ['data', 'pageNumber', 'currentItem', 'loading']),
        //Calculation for table footer data
        itemsSpan,
        //Vuex getters and setters for computed property
        subscriptionsPageNumber: {
            get () {
                return this.pageNumber
            },
            set (value) {
                this.UPDATE_PAGE_NUMBER(value)
            }
        }
    },
    watch:{
      currentItem:{
        handler(){
          this.CLEAN_DATA()
        }
      }  
    },
    methods: {
      ...mapActions('subscriptions', ['refreshData']),
      ...mapMutations('subscriptions', ['UPDATE_PAGE_NUMBER', 'CLEAN_DATA']),
      initialize,
      //Table pagination
      paginate(pageNumber){

      //Updating page number to vuex module
      this.subscriptionsPageNumber = pageNumber

      //Requesting new data with new pageNumber
      this.refreshData();

      }
    },
    created(){
        //Initializing needed modules
        this.initialize()

        //Stripe customer object needed for getting subscriptions
        // Getting customer from user id
        this.refreshData();
    }
}
</script>

<style>

</style>