<template>
    <v-row>
        <v-col cols="9" class="d-flex align-center">
            <!--Form fields-->
            <v-container fluid v-for="(form, index) in forms" :key="index" v-show="headerCurrentItem === form.title">
                <v-container v-if="headerCurrentItem === form.title" class="d-flex justify-evenly align-center" fluid>
                    <v-menu
                    v-for="(field, index) in form.data"
                    :key="index"
                    :ref="field.prop1"
                    v-model="$data[field.prop1]"
                    :close-on-content-click="false"
                    :return-value.sync="self()[field.prop2]"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="self()[field.prop2]"
                        :label="field.label"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        outlined
                        class="mr-5"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="self()[field.prop2]"
                    no-title
                    scrollable
                    color="primary"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="secondary"
                        @click="self()[field.prop1] = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="() => callRef(field.prop1, field.prop2)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-menu>
                
                <v-btn color="secondary" class="ml-5" v-if="showSubmitButton" @click="handleRefreshData">Submit</v-btn>
                
                </v-container>
            </v-container>
            <v-container fluid class="d-flex justify-evenly align-center" v-if="headerCurrentItem === 'Customer id'">
                <v-text-field
                v-model="headerCustomerId"
                label="Customer ID"
                hide-details
                outlined
                ></v-text-field>

                <v-btn color="secondary" class="ml-5" v-if="showSubmitButton" @click="handleRefreshData">Submit</v-btn>
            </v-container>
            <v-container fluid class="d-flex justify-evenly align-center" v-if="headerCurrentItem === 'Status'">
                <v-select
                v-model="headerStatus"
                :items="['active', 'canceled']"
                label="Status"
                hide-details
                outlined
                ></v-select>

                <v-btn color="secondary" class="ml-5" v-if="showSubmitButton" @click="handleRefreshData">Submit</v-btn>
            </v-container>
        </v-col>
        <v-col cols="3" class="d-flex justify-space-around align-center">
            <!--FILTERS-->
            <v-select
            :items="items"
            label="Filters"
            hide-details=""
            solo
            v-model="headerCurrentItem"
            ></v-select>

            <!--REFRESH-->
            <v-btn
            color="primary"
            fab
            medium
            dark
            class="ml-5 mr-5"
            @click="handleRefreshData"
            >
            <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
    data(){
        return{
            creationStartDateMenu: false,
            creationEndDateMenu: false,
            canceledStartDateMenu: false,
            canceledEndDateMenu: false,
            currentPeriodStartDateMenu: false,
            currentPeriodEndDateMenu: false,
            currentPeriodStartStartDateMenu: false,
            currentPeriodStartEndDateMenu: false,
            endedAtStartDateMenu: false,
            endedAtEndDateMenu: false,
            forms: [
                {
                    title: 'Cancellation date',
                    data:[
                        {   
                            label: 'Canceled start date',
                            prop1: 'canceledStartDateMenu',
                            prop2:  'headerCanceledStartDate'
                        },
                        {
                            label: 'canceledEndDate',
                            prop1: 'canceledEndDateMenu',
                            prop2: 'headerCanceledEndDate'
                        }
                    ]
                },
                {
                    title: 'Creation date',
                    data:[
                            {
                                label: 'Created start date',
                                prop1: 'creationStartDateMenu',
                                prop2: 'headerCreationStartDate'
                            },
                            {
                                label: 'Created end date',
                                prop1: 'creationEndDateMenu',
                                prop2: 'headerCreationEndDate'
                            }
                        ]
                },
                {
                    title: 'Current period end',
                    data:[
                            {
                                label: 'Current period end date',
                                prop1: 'currentPeriodStartDateMenu',
                                prop2: 'headerCurrentPeriodStartDate'
                            },
                            {
                                label: 'Current period end date',
                                prop1: 'currentPeriodEndDateMenu',
                                prop2: 'headerCurrentPeriodEndDate'
                            }
                        ]
                },
                {
                    title: 'Current period start',
                    data:[
                            {
                                label: 'Current period start date',
                                prop1: 'currentPeriodStartStartDateMenu',
                                prop2: 'headerCurrentPeriodStartStartDate'
                            },
                            {
                                label: 'Current period end date',
                                prop1: 'currentPeriodStartEndDateMenu',
                                prop2: 'headerCurrentPeriodStartEndDate'
                            }
                        ]
                },
                {
                    title: 'Ended at',
                    data:[
                            {
                                label: 'Ended at start date',
                                prop1: 'endedAtStartDateMenu',
                                prop2: 'headerEndedAtStartDate'
                            },
                            {
                                label: 'Ended at end date',
                                prop1: 'endedAtEndDateMenu',
                                prop2: 'headerEndedAtEndDate'
                            }
                        ]
                },
            ],
            modal: false,
            items:[
                'All',
                'Creation date',
                'Cancellation date',
                'Current period start',
                'Current period end',
                'Customer id',
                'Ended at',
                'Status'
            ],
        }
    },
    computed:{
        ...mapState('subscriptions', ['canceledStartDate', 'canceledEndDate', 'creationStartDate', 'creationEndDate', 'currentPeriodStartDate', 'currentPeriodEndDate', 'currentPeriodStartStartDate', 'currentPeriodStartEndDate', 'customerId', 'endedAtStartDate', 'endedAtEndDate', 'status', 'currentItem' ]),
        //Show submit button only if user needs to type some data to filter
        showSubmitButton(){
            let newItems = this.items.slice(1)
            return newItems.includes(this.headerCurrentItem)
        },
        //Vuex state getters and setters
        headerCanceledStartDate: {
            get () {
                return this.canceledStartDate
            },
            set (value) {
                this.UPDATE_CANCELED_START_DATE(value)
            }
        },
        headerCanceledEndDate: {
            get () {
                return this.canceledEndDate
            },
            set (value) {
                this.UPDATE_CANCELED_END_DATE(value)
            }
        },
        headerCreationStartDate: {
            get () {
                return this.creationStartDate
            },
            set (value) {
                this.UPDATE_CREATION_START_DATE(value)
            }
        },
        headerCreationEndDate: {
            get () {
                return this.creationEndDate
            },
            set (value) {
                this.UPDATE_CREATION_END_DATE(value)
            }
        },
        headerCurrentPeriodStartDate: {
            get () {
                return this.currentPeriodStartDate
            },
            set (value) {
                this.UPDATE_CURRENT_PERIOD_START_DATE(value)
            }
        },
        headerCurrentPeriodEndDate: {
            get () {
                return this.currentPeriodEndDate
            },
            set (value) {
                this.UPDATE_CURRENT_PERIOD_END_DATE(value)
            }
        },
        headerCurrentPeriodStartStartDate: {
            get () {
                return this.currentPeriodStartStartDate
            },
            set (value) {
                this.UPDATE_CURRENT_PERIOD_START_START_DATE(value)
            }
        },
        headerCurrentPeriodStartEndDate: {
            get () {
                return this.currentPeriodStartEndDate
            },
            set (value) {
                this.UPDATE_CURRENT_PERIOD_START_END_DATE(value)
            }
        },
        headerCustomerId: {
            get () {
                return this.customerId
            },
            set (value) {
                this.UPDATE_CUSTOMER_ID(value)
            }
        },
        headerEndedAtStartDate: {
            get () {
                return this.endedAtStartDate
            },
            set (value) {
                this.UPDATE_ENDED_AT_START_DATE(value)
            }
        },
        headerEndedAtEndDate: {
            get () {
                return this.endedAtEndDate
            },
            set (value) {
                this.UPDATE_ENDED_AT_END_DATE(value)
            }
        },
        headerStatus: {
            get () {
                return this.status
            },
            set (value) {
                this.UPDATE_STATUS(value)
            }
        },
        headerCurrentItem: {
            get () {
                return this.currentItem
            },
            set (value) {
                this.UPDATE_CURRENT_ITEM(value)
            }
        }
    },
    watch:{
        headerCurrentItem:{
            handler(){
                if(this.headerCurrentItem === 'All'){
                    //First get all subscriptions -> refreshData() knows that it needs to request all subscriptions
                    this.handleRefreshData()
                }

                //Clear/clean input fields from filters
                this.cleanFilterForms()
            }
        }
    },
    methods:{
        ...mapActions('subscriptions', ['refreshData']),
        ...mapMutations('subscriptions', ['UPDATE_PAGE_NUMBER', 'UPDATE_CANCELED_START_DATE', 'UPDATE_CANCELED_END_DATE', 'UPDATE_CREATION_START_DATE', 'UPDATE_CREATION_END_DATE', 'UPDATE_CURRENT_ITEM', 'UPDATE_CURRENT_PERIOD_START_DATE', 'UPDATE_CURRENT_PERIOD_END_DATE', 'UPDATE_CURRENT_PERIOD_START_START_DATE', 'UPDATE_CURRENT_PERIOD_START_END_DATE', 'UPDATE_ENDED_AT_START_DATE', 'UPDATE_ENDED_AT_END_DATE', 'UPDATE_CUSTOMER_ID', 'UPDATE_STATUS']),
        handleRefreshData(){
            this.UPDATE_PAGE_NUMBER(1)
            this.refreshData()
        },
        //Clean forms from filters
        cleanFilterForms(){
            this.headerCustomerId = "";
            this.headerSubscriptionId = "";
        },
        self(){
            return this;
        },
        callRef(prop1, prop2){
            //console.log(this.$refs)
            //Save choosen date
            this.$refs[prop1][0].save(this[prop2])
        }
    }
}
</script>